import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { Keyboard } from '@capacitor/keyboard';
import { IonApp, IonLoading, IonRouterOutlet } from '@ionic/angular/standalone';
import { HelperService } from './services/helper.service';
import { SupabaseService } from './services/supabase.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  standalone: true,
  imports: [IonLoading, IonApp, IonRouterOutlet], // AngularQueryDevtools
})
export class AppComponent implements AfterViewInit, OnInit, OnDestroy {
  platform = Capacitor.getPlatform();
  constructor(
    public helperService: HelperService,
    private supabaseService: SupabaseService
  ) {}

  async ngAfterViewInit() {
    this.supabaseService.cacheUser();
  }

  async ngOnInit() {
    if (Capacitor.getPlatform() === 'ios') {
      Keyboard.addListener('keyboardWillShow', info => {
        this.alertPadding(info.keyboardHeight / 2);
      });

      Keyboard.addListener('keyboardWillHide', () => {
        this.alertPadding(0);
      });
    }
  }

  async ngOnDestroy() {
    if (Capacitor.getPlatform() === 'ios') {
      Keyboard.removeAllListeners();
    }
  }

  alertPadding(padding: number) {
    const ionAlert = document.querySelector('ion-alert');
    if (ionAlert) {
      ionAlert.style.transform = 'translateY(-' + padding.toString() + 'px)';
    }
  }
}
