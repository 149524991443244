<ion-app
  [class.solakon-md]="platform == 'android' || platform == 'web'"
  [class.solakon-ios]="platform == 'ios'">
  <!--<angular-query-devtools />-->

  <ion-router-outlet></ion-router-outlet>
  <ion-loading
    translucent="true"
    [isOpen]="helperService.isLoading()"
    [message]="helperService.loadingMessage()"
    spinner="crescent"></ion-loading>
</ion-app>
