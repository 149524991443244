import {
  BarController,
  BarElement,
  CategoryScale,
  Chart,
  ChartData,
  ChartOptions,
  Filler,
  Legend,
  LinearScale,
  LineController,
  LineElement,
  PointElement,
  TimeScale,
  Tooltip,
} from 'chart.js';
import 'chartjs-adapter-date-fns';

function registerChartjsPlugins() {
  Chart.register(CategoryScale);
  Chart.register(LinearScale);
  Chart.register(BarController);
  Chart.register(BarElement);
  Chart.register(LineElement);
  Chart.register(LineController);
  Chart.register(Tooltip);
  Chart.register(Legend);
  Chart.register(PointElement);
  Chart.register(Filler);
  Chart.register(TimeScale);
}

export { registerChartjsPlugins };
