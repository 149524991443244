import {
  APP_INITIALIZER,
  ErrorHandler,
  LOCALE_ID,
  enableProdMode,
  importProvidersFrom,
  isDevMode,
} from '@angular/core';
import { bootstrapApplication } from '@angular/platform-browser';
import { PreloadAllModules, RouteReuseStrategy, Router, provideRouter, withPreloading } from '@angular/router';
import { IonicRouteStrategy, provideIonicAngular } from '@ionic/angular/standalone';

import { registerLocaleData } from '@angular/common';
import { provideHttpClient } from '@angular/common/http';
import localeDe from '@angular/common/locales/de';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { IonicStorageModule } from '@ionic/storage-angular';
import { provideTransloco } from '@jsverse/transloco';

import * as SentryAngular from '@sentry/angular';
import { QueryClient, provideAngularQuery } from '@tanstack/angular-query-experimental';
import { NgxCurrencyInputMode, provideEnvironmentNgxCurrency } from 'ngx-currency';
import { provideLottieOptions } from 'ngx-lottie';
import { AppComponent } from './app/app.component';
import { routes } from './app/app.routes';
import { TranslocoHttpLoader } from './app/transloco-loader';
import { environment } from './environments/environment';
import { provideHotToastConfig } from '@ngxpert/hot-toast';
import { FirebaseAnalytics } from '@capacitor-community/firebase-analytics';
import { Capacitor } from '@capacitor/core';
import { registerChartjsPlugins } from './app/libs/chartjs-plugins';

registerLocaleData(localeDe);

if (environment.production) {
  /*posthog.init('phc_JM0nZvIlHtxsye1W0HiFdqyN0gfM6sVdgycVc9pkQkH', {
    api_host: 'https://eu.i.posthog.com',
    person_profiles: 'identified_only',
  });*/
  SentryAngular.init({
    release: '1.0.0',
    dsn: 'https://2fe20165e1c72eaaf35557757c580209@sentry.solakon.de/5',
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,

    integrations: [
      SentryAngular.replayIntegration({
        maskAllInputs: false,
        maskAllText: false,
        blockAllMedia: false,
      }),
      SentryAngular.replayCanvasIntegration(),
    ],
  });
  enableProdMode();

  /*
  if (window) {
    window.console.log = () => {};
    window.console.error = () => {};
    window.console.warn = () => {};
  }*/
}

if (!Capacitor.isNativePlatform()) {
  FirebaseAnalytics.initializeFirebase({
    apiKey: 'AIzaSyCBjvdcuy7TkmHDpaR7XKOCwI8mQOfSrco',
    authDomain: 'solakon-app.firebaseapp.com',
    projectId: 'solakon-app',
    storageBucket: 'solakon-app.appspot.com',
    messagingSenderId: '464098819276',
    appId: '1:464098819276:android:d6e886e4c70dd71ee21669',
    measurementId: 'G-0Q0VZ7VJZC',
  });
}

bootstrapApplication(AppComponent, {
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: LOCALE_ID, useValue: 'de' },
    provideHotToastConfig(),
    provideTransloco({
      config: {
        availableLangs: ['de'],
        defaultLang: 'de',
        prodMode: !isDevMode(),
      },
      loader: TranslocoHttpLoader,
    }),
    provideIonicAngular({
      innerHTMLTemplatesEnabled: true,
      mode: 'ios',
      rippleEffect: true,
      backButtonText: '',
      spinner: 'circular',
      toastDuration: 4000,
      //
      scrollAssist: false,
      scrollPadding: false,
    }),
    provideEnvironmentNgxCurrency({
      align: 'left',
      allowNegative: false,
      allowZero: true,
      decimal: ',',
      precision: 2,
      prefix: '',
      suffix: '€',
      thousands: '.',
      nullable: true,
      min: null,
      max: null,
      inputMode: NgxCurrencyInputMode.Financial,
    }),
    provideLottieOptions({
      player: () => import('lottie-web'),
    }),
    provideRouter(routes, withPreloading(PreloadAllModules)),
    provideHttpClient(),
    provideAngularQuery(
      new QueryClient({
        defaultOptions: {
          queries: {
            refetchOnMount: false,
            refetchOnWindowFocus: false,
          },
        },
      })
    ),
    importProvidersFrom([BrowserAnimationsModule, IonicStorageModule.forRoot()]),
    {
      provide: ErrorHandler,
      useValue: SentryAngular.createErrorHandler(),
    },
    {
      provide: SentryAngular.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [SentryAngular.TraceService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {
        registerChartjsPlugins();
      },
      deps: [],
      multi: true,
    },
  ],
});
