import { Routes } from '@angular/router';
import { profileGuard } from './guards/profile.guard';

export const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./tabs/tabs.routes').then(m => m.routes),
  },
  {
    path: 'tmp',
    loadComponent: () => import('./test/test.page').then(m => m.TestPage),
  },
  {
    path: 'onboarding',
    loadComponent: () => import('./pages/onboarding/onboarding.page').then(m => m.OnboardingPage),
  },
  {
    path: 'welcome',
    loadComponent: () => import('./pages/welcome/welcome.page').then(m => m.WelcomePage),
  },
  {
    path: 'profile',
    canActivate: [profileGuard],
    loadComponent: () => import('./pages/profile/profile.page').then(m => m.ProfilePage),
  },
  {
    path: 'sign-in',
    loadComponent: () => import('./pages/sign-in/sign-in.page').then(m => m.SignInPage),
  },
  {
    path: 'edit-profile',
    canActivate: [profileGuard],
    loadComponent: () => import('./pages/edit-profile/edit-profile.page').then(m => m.EditProfilePage),
  },
  {
    path: 'sign-up',
    loadComponent: () => import('./pages/sign-up/sign-up.page').then(m => m.SignUpPage),
  },
  {
    path: 'dashboard',
    canActivate: [profileGuard],
    loadComponent: () => import('./pages/dashboard/dashboard.page').then(m => m.DashboardPage),
  },
  {
    path: 'inverter/:deviceId',
    canActivate: [profileGuard],
    loadComponent: () => import('./pages/inverter/inverter.page').then(m => m.InverterPage),
  },
  {
    path: 'faq',
    canActivate: [profileGuard],
    loadComponent: () => import('./pages/faq/faq.page').then(m => m.FAQPage),
  },
  {
    path: 'about-us',
    canActivate: [profileGuard],
    loadComponent: () => import('./pages/about-us/about-us.page').then(m => m.AboutUsPage),
  },
  {
    path: 'settings',
    canActivate: [profileGuard],
    loadComponent: () => import('./pages/settings/settings.page').then(m => m.SettingsPage),
  },
  {
    path: 'logout',
    loadComponent: () => import('./pages/logout/logout.page').then(m => m.LogoutPage),
  },
  {
    path: 'sign-up-intro',
    loadComponent: () => import('./pages/sign-up-intro/sign-up-intro.page').then(m => m.SignUpIntroPage),
  },
  {
    path: 'otp',
    loadComponent: () => import('./pages/otp/otp.page').then(m => m.OtpPage),
  },
  {
    path: 'inverter-setup-settings',
    canActivate: [profileGuard],
    loadComponent: () =>
      import('./pages/inverter-setup-settings/inverter-setup-settings.page').then(m => m.InverterSetupSettingsPage),
  },
  {
    path: 'inverter-setup-wifi',
    canActivate: [profileGuard],
    loadComponent: () =>
      import('./pages/inverter-setup-wifi/inverter-setup-wifi.page').then(m => m.InverterSetupWifiPage),
  },
  {
    path: 'notifications',
    canActivate: [profileGuard],
    loadComponent: () => import('./pages/notifications/notifications.page').then(m => m.NotificationsPage),
  },
  {
    path: 'contact',
    canActivate: [profileGuard],
    loadComponent: () => import('./pages/contact/contact.page').then(m => m.ContactPage),
  },
  {
    path: 'inverter-setup-done',
    canActivate: [profileGuard],
    loadComponent: () =>
      import('./pages/inverter-setup-done/inverter-setup-done.page').then(m => m.InverterSetupDonePage),
  },
  {
    path: 'devices',
    canActivate: [profileGuard],
    loadComponent: () => import('./pages/devices/devices.page').then(m => m.DevicesPage),
  },
  {
    path: 'inverter-setup-search',
    canActivate: [profileGuard],
    loadComponent: () =>
      import('./pages/inverter-setup-search/inverter-setup-search.page').then(m => m.InverterSetupSearchPage),
  },
  {
    path: 'onboarding-form',
    loadComponent: () => import('./pages/onboarding-form/onboarding-form.page').then(m => m.OnboardingFormPage),
  },
  {
    path: 'onboarding-done',
    loadComponent: () => import('./pages/onboarding-done/onboarding-done.page').then(m => m.OnboardingDonePage),
  },
  {
    path: 'inverter/:deviceId/settings',
    canActivate: [profileGuard],
    loadComponent: () => import('./pages/inverter-settings/inverter-settings.page').then(m => m.InverterSettingsPage),
  },
  {
    path: 'warranty',
    canActivate: [profileGuard],
    loadComponent: () => import('./pages/warranty/warranty.page').then(m => m.WarrantyPage),
  },
  {
    path: 'inverter-setup-error',
    canActivate: [profileGuard],
    loadComponent: () =>
      import('./pages/inverter-setup-error/inverter-setup-error.page').then(m => m.InverterSetupErrorPage),
  },
  {
    path: 'feature-request',
    canActivate: [profileGuard],
    loadComponent: () => import('./pages/feature-request/feature-request.page').then(m => m.FeatureRequestPage),
  },
];
